<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="customerAddressId != null">Edit Customer Address</b>
      <b v-else>Create Customer Address</b>
    </span>
    <vs-tabs>
      <vs-tab label="Address">
        <div class="tab-text">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input class="w-full" label="Code" v-model="data.code" name="Code" />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Status</label>
              <vs-checkbox v-model="data.status" vs-value="Billing Address"
                >Billing Address</vs-checkbox
              >
              <vs-checkbox v-model="data.status" vs-value="Shipping Address"
                >Shipping Address</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Territory</label>
              <multiselect
                v-model="data.territory.selected"
                :options="data.territory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('TerritoryCustomer')"
                >{{ errors.first("TerritoryCustomer") }}</span
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Zone</label>
              <multiselect
                v-model="data.zone.selected"
                :options="data.zone.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Sales</label>
              <multiselect
                v-model="data.sales.selected"
                :options="data.sales.options"
                placeholder="Type to search"
                track-by="Name"
                label="Name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.Name }} - {{ props.option.WorkIDNumber }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.Name }} - {{ props.option.WorkIDNumber }}</span>
                  </div>
                </template>
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('SalesCustomer')"
                >{{ errors.first("SalesCustomer") }}</span
              >
            </div>
          </div>

          <address-component
            :data="data.address"
            @data="setDataAddress"
          ></address-component>
        </div>
        
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Collector</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isTaxCollector"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Exempt</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isTaxExempt"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
      </vs-tab>
      <vs-tab label="Map">
        <div class="tab-text">
          <map-component :data="data.map" @data="setDataMap"></map-component>
        </div>
      </vs-tab>
      <vs-tab label="Contact">
        <div class="tab-text">
          <contact-component
            :data="data.contact"
            @data="setDataContact"
          ></contact-component>
        </div>
      </vs-tab>
      <vs-tab label="Work Operation">
        <div class="tab-text">
          <operation-component
            :data="data.workOperation"
            @data="setDataWorkOperation"
          ></operation-component>
        </div>
      </vs-tab>
      <vs-tab label="Receive">
        <div class="tab-text">
          <receive-operation-component
            :data="data.receiveOperation"
            :dataWorkOperation="data.workOperation"
            @data="setDataReceiveOperation"
          ></receive-operation-component>
        </div>
      </vs-tab>
    </vs-tabs>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OperationComponent from "./form-component/Operation";
import ContactComponent from "./form-component/Contact";
import MapComponent from "./form-component/Map";
import AddressComponent from "@/components/master/Address";
import ReceiveOperationComponent from "./form-component/ReceiveOperation";

export default {
  components: {
    flatPickr,
    OperationComponent,
    ContactComponent,
    MapComponent,
    AddressComponent,
    ReceiveOperationComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
    customerAddressId: {
      type: Number,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          customerId: null,
          status: [],
          code: "",
          isTaxCollector: true,
          isTaxExempt: true,
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
          map: {
            address: "",
            latitude: "",
            longitude: "",
          },
          contact: {
            name: "",
            mobile: "",
            phone: "",
            email: "",
            fax: "",
          },
          receiveOperation: [],
          workOperation: [],
          territory: {
            options: [],
            selected: null,
          },
          zone: {
            options: [],
            selected: null,
          },
          sales: {
            selected: null,
            options: [],
          },
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.territory.selected) {
          this.errors.add({
            field: "TerritoryCustomer",
            msg: "The territory field is required",
          });
          result = false;
        }
        if (!this.data.sales.selected) {
          this.errors.add({
            field: "SalesCustomer",
            msg: "The Sales field is required",
          });
          result = false;
        }
        if (result) {
          this.$vs.loading();
          if (this.customerAddressId) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      
      let is_tax_collector = "0";
      if (this.data.isTaxCollector) {
        is_tax_collector = "1";
      }
      let is_tax_exempt = "0";
      if (this.data.isTaxExempt) {
        is_tax_exempt = "1";
      }

      let workOperation = [];
      let receiveOperation = [];
      this.data.workOperation.forEach((item) => {
        if (item.checked) {
          workOperation.push({
            day: item.day,
            start_time: item.start_time,
            end_time: item.end_time,
          });
        }
      });
      this.data.receiveOperation.forEach((item) => {
        if (item.checked) {
          receiveOperation.push({
            day: item.day,
            start_time: item.start_time,
            end_time: item.end_time,
          });
        }
      });
      return {
        customer_id: this.customerId,
        code: this.data.code,
        territory_id: this.data.territory.selected.id ? this.data.territory.selected.id : this.data.territory.selected.ID,
        zone_id: this.data.zone.selected.ID,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        status: JSON.stringify(this.data.status),

        map_address: this.data.map.address,
        latitude: this.data.map.latitude,
        longitude: this.data.map.longitude,

        contact_name: this.data.contact.name,
        contact_mobile: this.data.contact.mobile,
        phone: this.data.contact.phone,
        email: this.data.contact.email,
        fax: this.data.contact.fax,

        work_operations: workOperation,
        receive_operations: receiveOperation,

        sales_personal_id: this.data.sales.selected.ID,
        is_tax_collector: is_tax_collector,
        is_tax_exempt: is_tax_exempt,
      };
    },
    postData() {
      this.$http
        .post("/api/v1/master/customer-address", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Customer Address Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(
          "/api/v1/master/customer-address/" + this.customerAddressId,
          this.paramData()
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Customer Adress Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerAddressId) {
        this.$http
          .get("/api/v1/master/customer-address/" + this.customerAddressId)
          .then((resp) => {
            this.$vs.loading.close();
            console.log(resp)
            if (resp.code == 200) {
              this.data.customerId = resp.data.customer_id;
              this.data.code = resp.data.code;
              this.data.territory.selected = resp.data.territory;
              this.data.zone.selected = resp.data.zone;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
              this.data.sales.selected = resp.data.sales;
              console.log(resp.data)
              if (resp.data.is_tax_collector == "1") {
                this.data.isTaxCollector = true;
              } else {
                this.data.isTaxCollector = false;
              }
              if (resp.data.is_tax_exempt == "1") {
                this.data.isTaxExempt = true;
              } else {
                this.data.isTaxExempt = false;
              }

              if (resp.data.status) {
                this.data.status = JSON.parse(resp.data.status);
              }

              this.data.map.address = resp.data.map_address;
              this.data.map.latitude = resp.data.latitude;
              this.data.map.longitude = resp.data.longitude;

              this.data.contact.name = resp.data.contact_name;
              this.data.contact.mobile = resp.data.contact_mobile;
              this.data.contact.phone = resp.data.phone;
              this.data.contact.email = resp.data.email;
              this.data.contact.fax = resp.data.fax;

              if (resp.data.work_operation) {
                if (resp.data.work_operation.work_operation_detail) {
                  this.data.workOperation = [];
                  resp.data.work_operation.work_operation_detail.forEach(
                    (item) => {
                      this.data.workOperation.push({
                        day: item.work_day.day,
                        checked: true,
                        start_time: item.work_time.start_time
                          .toString()
                          .replace(/\./g, ":"),
                        end_time: item.work_time.end_time
                          .toString()
                          .replace(/\./g, ":"),
                      });
                    }
                  );
                }
              }
              if (resp.data.receive_time) {
                if (resp.data.receive_time.work_operation_detail) {
                  this.data.receiveOperation = [];
                  resp.data.receive_time.work_operation_detail.forEach(
                    (item) => {
                      this.data.receiveOperation.push({
                        day: item.work_day.day,
                        checked: true,
                        start_time: item.work_time.start_time
                          .toString()
                          .replace(/\./g, ":"),
                        end_time: item.work_time.end_time
                          .toString()
                          .replace(/\./g, ":"),
                      });
                    }
                  );
                }
              }

              // if (resp.data.zone_id) {
              //   this.data.sales.selected = null;
              //   //this.setZoneSelected(resp.data.customer_zone.zone_id);
              //   this.getDataSales(resp.data.zone_id);
              // } else {
              //   //this.data.zone.selected = [];
              //   this.data.sales.selected = null;
              // }

            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        if (this.customerId) {
          this.$http
            .get("/api/v1/master/customer/" + this.customerId)
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.data.address.address = resp.data.address;
                this.data.address.postalCode = resp.data.postal_code;
                this.data.address.countryName = resp.data.country;
                this.data.address.provinceName = resp.data.province;
                this.data.address.cityName = resp.data.city;
                this.data.address.districtName = resp.data.district;
                this.data.address.subdistrictName = resp.data.sub_district;
                // this.data.map.address = resp.data.map_address;
                // this.data.map.latitude = resp.data.latitude;
                // this.data.map.longitude = resp.data.longitude
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      }
    },
    getDataTerritories() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataZones() {
      let ids = [];
      this.data.zone.options = [];
      if (this.data.territory.selected) {
        ids.push(this.data.territory.selected.id ? this.data.territory.selected.id : this.data.territory.selected.ID );
        this.$vs.loading();
        this.$http
          .get("/api/v1/master/zone/territory", {
            params: {
              territory_ids: ids,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.data.zone.options = resp.data;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    getDataTerritory(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.territory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDataZone(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/zone/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.zone.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDataSales(zoneId) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-sales/zone/" + zoneId)
        .then((resp) => {
          if (resp.status == "success") {
            this.data.sales.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setDataReceiveOperation(val) {
      this.data.receiveOperation = val;
    },
    setDataWorkOperation(val) {
      this.data.workOperation = val;
    },
    setDataContact(val) {
      this.data.contact = val;
    },
    setDataMap(val) {
      this.data.map = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
  },
  mounted() {
    this.getData();
    this.getDataTerritories();
  },
  computed: {},
  watch: {
    customerAddressId(v) {
      if (v) {
        this.getData();
      } else {
        this.getData();
        Object.assign(this.$data, this.initialState());
      }
    },
    "data.territory.selected": function (val) {
      this.data.territory.selected = val;
      if (val) {
        if (this.data.zone.selected) {
          if (val.code !== this.data.zone.selected.territory_code) {
            this.data.zone.selected = null;
          }
        }
      } else {
        this.data.zone.selected = null;
      }

      this.getDataZones();
    },
    "data.zone.selected": function (val) {
      this.data.zone.selected = val;
      if (this.data.zone.selected) {
        this.getDataSales(this.data.zone.selected.ID);
      }
      
    },
  },
};
</script>
<style >
</style>
